import React from "react"

import { Box, BoxedContainer, Flex } from "../../elements"
import Subscribe from "./Subscribe"
import Logo from "../../images/inline-svgs/traefik-labs-white.svg"
import SocialMediaIcons from "./SocialMediaIcons"
import { Column, ColumnItem, Columns } from "./Column"
import Copyright from "./Copyright"

const Footer = () => {
  return (
    <Box
      as="footer"
      bg="background.dark"
      color="text.inverted.base"
      className="site-footer"
      pt={["45px"]}
      pb={["40px"]}
    >
      <BoxedContainer>
        <Flex
          flexDirection={["column", null, "row"]}
          alignItems={["flex-start", null, "center"]}
          justifyContent={["space-between"]}
          position={"relative"}
        >
          <Flex
            order={["2", null, "1"]}
            mt={["45px", null, 0]}
            maxWidth={[`100%`, `270px`]}
            width={`100%`}
            position={"relative"}
            zIndex={1}
          >
            <Subscribe />
          </Flex>
          <Flex
            order={["1", null, "2"]}
            flexDirection={["column", "row"]}
            width={["100%", null, "auto"]}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Flex
              order={["2", null, "1"]}
              mt={["24px", 0]}
              position={[null, null, "absolute"]}
              left={[null, null, "0"]}
              width={[null, null, "100%"]}
              justifyContent={[null, null, "center"]}
            >
              <SocialMediaIcons />
            </Flex>
            <Flex order={["1", null, "2"]}>
              <Logo />
            </Flex>
          </Flex>
        </Flex>

        <Box as="nav" aria-label="Site">
          <Columns
            as="ul"
            gridTemplateColumns={[
              "repeat(2, 1fr)",
              null,
              "repeat(3, 1fr)",
              "repeat(6, 1fr)",
            ]}
            gridGap={["36px 24px", null, "36px 40px"]}
            m={`48px 0 0`}
          >
            <Column title="Traefik Enterprise">
              <ColumnItem variant="internal" href="/traefik-enterprise/">
                Overview
              </ColumnItem>
              <ColumnItem variant="internal" href="/pricing/">
                Pricing
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
              >
                Free trial
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://support.traefik.io/"
                rel="noopener noreferrer"
              >
                Support Center
              </ColumnItem>
            </Column>

            <Column
              title={
                <>
                  Traefik Hub{" "}
                  <Flex
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="product.hub.accent"
                    color="product.hub.bg"
                    borderRadius="6px"
                    p="1px 4px 1px 7px"
                    textAlign="center"
                    fontSize="1.1rem"
                    lineHeight="1"
                    height="18px"
                  >
                    Beta
                  </Flex>{" "}
                </>
              }
            >
              <ColumnItem variant="internal" href="/traefik-hub/">
                Overview
              </ColumnItem>
              {/* <ColumnItem variant="internal" href="/traefik-hub/pricing/">
                Pricing
              </ColumnItem> */}
              <ColumnItem
                variant="external"
                href="https://hub.traefik.io/"
                rel="noopener noreferrer"
              >
                Sign Up for Free
              </ColumnItem>
            </Column>

            <Column title="Open Source">
              <ColumnItem variant="internal" href="/traefik/">
                Traefik Proxy
              </ColumnItem>
              <ColumnItem variant="internal" href="/traefik-mesh/">
                Traefik Mesh
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://github.com/traefik"
                rel="noopener noreferrer"
              >
                Github
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://doc.traefik.io/"
                rel="noopener noreferrer"
              >
                Documentation
              </ColumnItem>
            </Column>

            <Column title="Learn">
              <ColumnItem variant="internal" href="/blog/">
                Blog
              </ColumnItem>
              <ColumnItem variant="internal" href="/resources/">
                Resource Library
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://academy.traefik.io/"
                rel="noopener noreferrer"
              >
                Traefik Academy
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://doc.traefik.io/"
                rel="noopener noreferrer"
              >
                Documentation
              </ColumnItem>
              <ColumnItem variant="internal" href="/success-stories/">
                Success Stories
              </ColumnItem>
              <ColumnItem variant="internal" href="/glossary/">
                Glossary
              </ColumnItem>
              <ColumnItem variant="internal" href="/events/">
                Events
              </ColumnItem>
            </Column>

            <Column title="Community">
              <ColumnItem variant="internal" href="/community/">
                Join the Community
              </ColumnItem>
              <ColumnItem
                variant="external"
                href="https://community.traefik.io/"
                rel="noopener noreferrer"
              >
                Forum
              </ColumnItem>
              <ColumnItem variant="internal" href="/community-library/">
                Community Library
              </ColumnItem>
              <ColumnItem
                variant="internal"
                href="/traefik-ambassador-program/"
              >
                Traefik Ambassadors
              </ColumnItem>
            </Column>

            <Column title="Company">
              <ColumnItem variant="internal" href="/about-us/">
                About
              </ColumnItem>
              <ColumnItem variant="internal" href="/partners/">
                Partners
              </ColumnItem>
              <ColumnItem variant="internal" href="/careers/">
                Careers
              </ColumnItem>
              <ColumnItem variant="internal" href="/press/">
                Press
              </ColumnItem>
              <ColumnItem variant="internal" href="/contact-us/">
                Contact Us
              </ColumnItem>
            </Column>
          </Columns>
        </Box>

        <Flex mt={`48px`}>
          <Copyright />
        </Flex>
      </BoxedContainer>
    </Box>
  )
}

export default Footer
