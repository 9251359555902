import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"

import Header from "../Header"
import Footer from "../Footer"
import Announcement from "../Announcement"
import CookieNotice from "../CookieNotice"

import theme from "../../styles/theme"
import "../../styles/main.scss"

interface Props {
  children?: any
}

const Layout = ({ children }: Props) => {
  // Initialize Google optimize experiment on event: 'optimize.activate'
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "optimize.activate",
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Announcement />
      <Header hideNavigation={false} />
      {children}
      <Footer />
      <CookieNotice />
    </ThemeProvider>
  )
}

export default Layout
