import styled from "styled-components"

const Middot = styled.span.attrs({
  "aria-hidden": "true",
})`
  &:before {
    content: "·";
    font-size: 2rem;
    color: ${props => props.theme.colors.text.base};
    margin: 0 8px;
  }
`

export default Middot
