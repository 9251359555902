import React from "react"
import styled from "styled-components"
import { Link as A } from "gatsby"
import { Box, Text } from "../../elements"

const Links = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 0 18px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: 1.6rem;
    line-height: 1.38;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.text.dark};

    &:hover {
      color: ${props => props.theme.colors.primary.base};
    }
  }
`

type MenuColumnProps = {
  title: string
}

export const Column: React.FC<MenuColumnProps> = ({ title, children }) => (
  <Box>
    <Text
      as="p"
      variant="uppercase"
      mb="18px"
      fontSize={["1.1rem", null, null, "1.1rem"]}
      lineHeight="1"
    >
      {title}
    </Text>
    <Links>{children}</Links>
  </Box>
)

type MenuColumnLinkProps = {
  href: string
  external?: boolean
}

export const Item: React.FC<MenuColumnLinkProps> = ({
  href,
  external,
  children,
  ...props
}) => (
  <li>
    {external ? (
      <a href={href} {...props}>
        {children}
      </a>
    ) : (
      <A to={href} {...props}>
        {children}
      </A>
    )}
  </li>
)
