import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Search as SearchIcon } from "@styled-icons/feather/Search"

const Search = () => (
  <Wrapper to="/search/">
    <SearchIcon strokeWidth="2" />
  </Wrapper>
)

const Wrapper = styled(Link)`
  width: 36px;
  height: 36px;
  margin: 0 8px 0 0;
  color: ${p => p.theme.colors.text.dark};
  padding: 7px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
  }

  &:hover {
    color: ${p => p.theme.colors.text.dark};
    background: ${p => p.theme.colors.background.light};
  }

  @media (min-width: ${p => p.theme.breakpoints[2]}) {
    margin: 0 24px 0 0;
  }
`

export default Search
