import styled, { css } from "styled-components"
import { space, layout, color, background } from "styled-system"

import theme from "../../styles/theme"

const Container = styled.section<{
  withPadding?: boolean
}>`
  ${props =>
    props.withPadding &&
    css`
      padding: 80px 0;
      @media (max-width: ${theme.breakpointsObj.lg}) {
        padding: 60px 0;
      }
    `}

  ${space};
  ${layout};
  ${color};
  ${background};
`

export default Container

// Container for links at bottom of a section
export const CTAs = styled.div`
  margin: 40px 0 0;
  text-align: center;
`
