import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Box, Text } from "../../elements"
import Chevron from "../../images/inline-svgs/chevron-down.svg"
import { space, SpaceProps } from "styled-system"

type NavItemProps = SpaceProps & {
  name: string
  hasSubmenu?: boolean
  link?: {
    type: "internal" | "external"
    url: string
  }
  menuProps?: any
}

const NavItem: React.FC<NavItemProps> = ({
  name,
  hasSubmenu,
  link,
  menuProps,
  children,
  ...props
}) => {
  if (hasSubmenu) {
    return (
      <Wrapper {...props}>
        <Title aria-haspopup="menu" aria-expanded={false}>
          <Text fontSize="1.45rem" lineHeight="1" fontWeight="500">
            {name}
          </Text>
          <Box as="span" width="16px" height="16px" lineHeight="1" ml="3px">
            <Chevron />
          </Box>
        </Title>
        <Menu {...menuProps}>
          <MenuInner>{children}</MenuInner>
        </Menu>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...props}>
      {link?.type == "internal" ? (
        <Link to={link.url}>
          <Title as="span">
            <Text fontSize="1.45rem" lineHeight="1" fontWeight="500">
              {name}
            </Text>
          </Title>
        </Link>
      ) : (
        <a href={link?.url}>
          <Title as="span">
            <Text fontSize="1.45rem" lineHeight="1" fontWeight="500">
              {name}
            </Text>
          </Title>
        </a>
      )}
    </Wrapper>
  )
}

const Title = styled.button`
  color: ${({ theme }) => theme.colors.text.dark};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    stroke-width: 2.5;
    width: 100%;
    height: 100%;
  }
`

const Menu = styled(Box)`
  position: absolute;
  z-index: 500;
  margin: 0;
  display: none;
  background: transparent;
  padding-top: 16px;
`

const MenuInner = styled(Box)`
  background: ${props => props.theme.colors.background.base};
  border-radius: ${props => props.theme.radius.large};
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  overflow: hidden;
`

const Wrapper = styled.li`
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0 0 0 24px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    ${Menu} {
      display: block;
    }

    ${Title} {
      box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.colors.text.dark};
    }
  }

  ${space}
`

export default NavItem
