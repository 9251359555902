import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"

import "./Announcement.scss"

const Announcement = () => {
  const query = useStaticQuery(graphql`
    query Annoucement {
      ghostPage(slug: { eq: "announcement" }) {
        id
        html
      }
    }
  `)

  const [updateNow, setUpdateNow] = useState(true) // Used only to re-render the component

  const closeBanner = () => {
    localStorage.setItem("announcementBanner", query.ghostPage.html)
    setUpdateNow(!updateNow) // Re-render the component
  }

  const getVisibility = () => {
    if (typeof localStorage !== "undefined") {
      // When not in SSR stage
      if (
        localStorage.getItem("announcementBanner") === query.ghostPage?.html
      ) {
        return null
      } else {
        localStorage.removeItem("announcementBanner")
        return true
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    if (query.ghostPage === null) {
      localStorage.removeItem("announcementBanner")
    }
  }, [])

  return (
    (query.ghostPage?.html ? true : null) &&
    getVisibility() && (
      <aside className="announcement-banner">
        <div className="announcement visible">
          <div className="wrapper-1200">
            <div className="left">
              <div className="announcement__new-tag">New</div>
              <div
                className="announcement__title"
                dangerouslySetInnerHTML={{
                  __html: query.ghostPage.html,
                }}
              ></div>
            </div>
            <div className="right">
              <button
                type="button"
                onClick={() => closeBanner()}
                className="announcement__close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </aside>
    )
  )
}

export default Announcement
