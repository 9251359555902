import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Box, Text, Heading, StyledLink as LearnMore } from "../../elements"

type NavProductProps = {
  logo: string
  title: string
  titleHtml?: ReactNode
  description: string
  url: string
  color: string
  bgColor?: string
  links?: {
    title: string
    url: string
    external: boolean
    icon: ReactNode
  }[]
}

const Product: React.FC<NavProductProps> = ({
  logo,
  title,
  titleHtml,
  description,
  url,
  links,
  color,
  bgColor,
}) => (
  <Box p={`40px`} bg={bgColor || "#fff"}>
    <Box>
      <img src={logo} aria-hidden="true" />
    </Box>
    {titleHtml || (
      <Heading variant="default" fontSize="2rem" mt={`12px`}>
        {title}
      </Heading>
    )}
    <Text as="p" fontSize="1.6rem" mt={2} color="text.lighter">
      {description}
    </Text>
    <LearnMore
      to={url}
      icon="arrow"
      iconHoverTransform={true}
      color={color}
      mt={`10px`}
    >
      Learn more
    </LearnMore>
    <Line />
    <Links>
      {links &&
        links.map(link => (
          <li key={title + link.title}>
            {link.external ? (
              <a href={link.url}>
                {link.icon}
                {link.title}
              </a>
            ) : (
              <Link to={link.url}>
                {link.icon}
                {link.title}
              </Link>
            )}
          </li>
        ))}
    </Links>
  </Box>
)

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0 19px;
  background: linear-gradient(
    to right,
    #ccd1d5 0%,
    rgba(204, 209, 213, 0) 100%
  );
`

const Links = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0 0 8px;

    &:last-child {
      margin: 0;
    }
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: ${props => props.theme.fontWeights.medium};
    line-height: 1.14;
    color: ${props => props.theme.colors.text.lighter};

    &:hover {
      color: ${props => props.theme.colors.text.base};
    }

    svg {
      margin-right: 8px;
    }
  }
`

export default Product
