import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
  position,
  PositionProps,
} from "styled-system"

type FlexProps = FlexboxProps &
  ColorProps &
  SpaceProps &
  TypographyProps &
  LayoutProps &
  BorderProps &
  PositionProps

const Flex = styled.div<FlexProps>`
  display: flex;
  ${flexbox};
  ${color};
  ${space};
  ${typography};
  ${layout};
  ${border};
  ${position};
`

export default Flex
