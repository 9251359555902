import { Link } from "gatsby"
import React from "react"

import Chevron from "../../images/inline-svgs/chevron-down.svg"

import "./DrawerNav.scss"

function toggleMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  e.currentTarget.classList.toggle("open")
}

interface Props {
  isDrawerOpen: boolean
}

const DrawerNav = ({ isDrawerOpen }: Props) => {
  return (
    <div className={"drawer-menu pull-left " + (isDrawerOpen ? "open" : "")}>
      <div className="navigation">
        <div
          className="menu-item-wrapper menu-item-wrapper--expandable"
          onClick={e => toggleMenu(e)}
        >
          <div className="menu-item menu-item--with-icon">
            <span className="title">Products</span>
            <span className="icon">
              <Chevron />
            </span>
          </div>
          <div className="sub-menu">
            <Link to="/traefik-enterprise/">Traefik Enterprise</Link>
            <Link to="/traefik-hub/">Traefik Hub (Beta)</Link>
          </div>
        </div>

        <div
          className="menu-item-wrapper menu-item-wrapper--expandable"
          onClick={e => toggleMenu(e)}
        >
          <div className="menu-item menu-item--with-icon">
            <span className="title">Open Source</span>
            <span className="icon">
              <Chevron />
            </span>
          </div>
          <div className="sub-menu">
            <Link to="/traefik/">Traefik Proxy</Link>
            <Link to="/traefik-mesh/">Traefik Mesh</Link>
          </div>
        </div>

        <div
          className="menu-item-wrapper menu-item-wrapper--expandable"
          onClick={e => toggleMenu(e)}
        >
          <a className="menu-item menu-item--with-icon">
            <span className="title">Learn</span>
            <span className="icon">
              <Chevron />
            </span>
          </a>
          <div className="sub-menu">
            <Link to="/blog/">Blog</Link>
            <Link to="/resources/">Resource Library</Link>
            <a href="https://academy.traefik.io/">Traefik Academy</a>
            <Link to="/success-stories/">Success Stories</Link>
            <Link to="/events/">Events</Link>
            <a href="https://doc.traefik.io">Docs</a>
            <Link to="/community/">Community</Link>
            <a href="https://community.traefik.io/">Forum</a>
          </div>
        </div>

        <div className="menu-item-wrapper">
          <Link to="/pricing/" className="menu-item">
            Pricing
          </Link>
        </div>

        <div className="menu-item-wrapper">
          <Link to="/contact-us/" className="menu-item">
            Get in Touch
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DrawerNav
