import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Flex, Box, BoxedContainer, Text, Button } from "../../elements"

const CookieNotice = () => {
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("cookie_consent") == null) {
      setVisibility(true)
    }
  }, [])

  const close = () => {
    localStorage.setItem("cookie_consent", true)
    setVisibility(false)
  }

  return (
    <Box
      bg="#f9fafa"
      position="fixed"
      bottom={0}
      width="100%"
      display={visibility ? "block" : "none"}
      zIndex={2000}
      boxShadow="0 -6px 12px 0 rgba(31, 3, 35, 0.03)"
    >
      <BoxedContainer>
        <Flex py="10px" maxWidth="1017px" mx="auto" alignItems="center">
          <Text
            fontSize={["1.4rem", null, "1.6rem"]}
            as="p"
            color="text.dark"
            pr="24px"
          >
            Traefik Labs uses cookies to improve your experience. By continuing
            to browse the site you are agreeing to our use of cookies. Find out
            more in the{" "}
            <Link to="/legal/privacy-and-cookie-policy/">Cookie Policy</Link>.
          </Text>
          <Button
            elementType="button"
            uppercase
            onClick={() => close()}
            bg="#03192d"
            height="40px"
          >
            OK
          </Button>
        </Flex>
      </BoxedContainer>
    </Box>
  )
}

export default CookieNotice
