const theme = {
  fontSizes: ["1.2rem", "1.4rem", "1.6rem", "1.8rem", "2rem", "2.4rem", "2.6rem", "3rem", "3.4rem", "3.6rem", "4rem", "4.8rem"],

  colors: {
    primary: {
      base: "hsl(222deg, 75%, 48%)",
      dark: "hsl(222deg, 75%, 42%)"
    },
    text: {
      lighter: "#677581",
      light: "#4e5e6c",
      base: "#505769",
      dark: "#03192d",

      // Text colors for dark backgrounds
      inverted: {
        light: "#fff",
        base: "hsl(180deg, 9%, 98%)",
        dark: "hsl(180deg, 9%, 88%)",
        dark2x: "hsl(180deg, 9%, 78%)"
      },
    },
    borders: {
      base: "#E5E5E5",
      darker: "#b8bdc1",
    },
    background: {
      light: "#f8faff",
      base: "#ffffff",
      dark: "#03192d",
    },
    greys: {
      light2x: "#e5e8ea",
      light: "hsl(204deg, 11%, 86%)",
      base: "#B8BDC1",
    },
    accent: {
      orange: {
        base: "#db7d11",
      },
      teal: {
        base: "#00a697",
      },
      purple: {
        base: "#67519e",
      },
      magenta: {
        base: "#9d0fb0",
      },
      turquoise: {
        base: "#24a1c1",
      },
    },
    product: {
      hub: {
        accent: "hsl(68deg, 79%, 60%)",
        dark: "hsl(68deg, 79%, 47%)",
        dark2x: "hsl(68deg, 53%, 36%)",
        bg: "hsl(209deg, 88%, 9%)",
        bg2x: "#021424",
        bgLight: "#f9fafa",
        text: "#4e5e6c",
        textLight: "#b3bac0",
      },
    },
  },
  breakpoints: ["700px", "900px", "1024px", "1274px", "1400px", "1600px"],
  breakpointsObj: {
    sm: "700px",
    md: "900px",
    lg: "1024px",
    xl: "1274px",
    xxl: "1400px",
    xxxl: "1600px"
  },
  shadows: {
    base: "0 6px 12px 0 rgb(31, 3, 35, 3%)",
    darker: "0 0 4px 0 #00000021",
  },
  radius: {
    small: "4px",
    default: "6px",
    large: "8px",
    xlarge: "16px",
    xxlarge: "24px"
  },
  // to be removed. instead fontWeight to be used (as required by Styled System)
  weights: {
    normal: "400",
    medium: "500",
    bold: "700",
  },
  fontWeights: {
    normal: "400",
    medium: "500",
    bold: "700",
  },

  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512], // spaces in px
}

const padding = {
  // For padding along the y axis 
  // Usually used on page sections
  yaxis: {
    default: ["60px", null, null, "80px"],
    medium: ["60px", null, null, "113px"],
    large: ["60px", null, null, "144px"]
  }
}

export { theme as default, padding }
