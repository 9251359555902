import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, StyledLink } from "../../elements"
import { Post } from "../../types/global"

type MenuPostCard = {
  post: Post
}

const PostCard: React.FC<MenuPostCard> = ({ post }) => {
  const featuredImage = getImage(post.featureImageSharp)

  return (
    <Box bg="#f4f5f6" minHeight="280px" borderRadius="8px" overflow="hidden">
      <Link to={`/${post.primary_tag.slug}/${post.slug}/`}>
        <Box>
          {featuredImage && (
            <GatsbyImage
              image={featuredImage}
              alt={post.title}
              imgStyle={{
                objectFit: "cover",
                height: "162px",
              }}
              style={{
                height: "162px",
              }}
            />
          )}
        </Box>
        <Flex p="16px" flexDirection="column">
          <Heading
            as="p"
            variant="xs"
            mb="16px"
            flex="1"
            color="text.dark"
            fontWeight="medium"
          >
            {post.title}
          </Heading>
          <StyledLink as="div" icon="arrow" color="text.dark">
            Read more
          </StyledLink>
        </Flex>
      </Link>
    </Box>
  )
}

export default PostCard
