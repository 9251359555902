import styled from "styled-components"
import { variant } from "styled-system"

import Box from "./Box"

type Props = {
  variant?: "default" | "narrow" | "small"
}

const BoxedContainer = styled(Box)<Props>(
  {
    width: "100%",
    margin: "0 auto",
  },
  variant({
    variants: {
      default: {
        maxWidth: "1274px",
        padding: ["0 20px", "0 25px"],
      },
      narrow: {
        maxWidth: "1066px",
        padding: ["0 20px", "0 25px"],
      },
      small: {
        maxWidth: "858px",
        padding: ["0 20px", "0 25px"],
      },
    },
  })
)

BoxedContainer.defaultProps = {
  variant: "default",
}

export default BoxedContainer
