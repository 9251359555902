import React from "react"
import { Link, GatsbyLinkProps } from "gatsby"
import styled, { css } from "styled-components"
import {
  space,
  color,
  typography,
  SpaceProps,
  ColorProps,
  TypographyProps,
  position,
  PositionProps,
} from "styled-system"

import theme from "../styles/theme"

import ArrowBlue from "../images/icons/right-arrow-blue.svg"
import Arrow from "../images/inline-svgs/arrow-right.svg"

type LinkProps = SpaceProps &
  ColorProps &
  TypographyProps &
  PositionProps &
  GatsbyLinkProps & {
    isExternal?: boolean
    icon?: string | React.ReactNode
    iconHoverTransform?: boolean
    hover?: {
      color?: string
    }
  }

const StyledLink: React.FC<LinkProps> = ({
  children,
  isExternal,
  icon,
  ...props
}) => {
  return isExternal ? (
    <ExternalLink {...props}>
      {children}
      {icon && <Icon>{icon === "arrow" ? <Arrow /> : icon}</Icon>}
    </ExternalLink>
  ) : (
    <GatsbyLink {...props}>
      {children}
      {icon && <Icon>{icon === "arrow" ? <Arrow /> : icon}</Icon>}
    </GatsbyLink>
  )
}

StyledLink.defaultProps = {
  iconHoverTransform: true,
}

const linkStyles = css<LinkProps>`
  font-size: 1.6rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    color: ${props => props.hover?.color};

    ${props =>
      props.iconHoverTransform &&
      css`
        ${Icon} {
          transform: translateX(4px);
        }
      `}
  }

  ${space};
  ${color};
  ${typography};
  ${position};
`

const defaultLinkProps = (props: LinkProps) => ({
  fontWeight: props.fontWeight || "medium",
  color: props.color || theme.colors.primary.base,
  hover: {
    color: props.hover?.color || props.color || theme.colors.primary.dark,
  },
  iconHoverTransform: !!props.iconHoverTransform,
})

const ExternalLink = styled.a.attrs(defaultLinkProps)`
  ${linkStyles}
`

const GatsbyLink = styled(Link).attrs(defaultLinkProps)`
  ${linkStyles}
`

const Icon = styled.span`
  width: 24px;
  height: 24px;
  margin-left: 6px;
  transition: transform 0.1s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
  }
`

// To be removed!
const LinkWithArrowStyles = css`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${p => p.theme.colors.primary.base};
  display: inline-flex;
  align-items: center;
  transition: all 0.2s;

  &::after {
    content: "";
    background-image: url(${ArrowBlue});
    width: 23px;
    height: 23px;
    background-position: center;
    display: inline-block;
    margin-left: 6px;
    margin-top: 1px;
    background-repeat: no-repeat;
    transition: all 0.05s;
  }

  &:hover {
    &::after {
      margin-left: 8px;
    }
  }
`

export { StyledLink, LinkWithArrowStyles }
