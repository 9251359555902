import styled from "styled-components"
import {
  space,
  layout,
  SpaceProps,
  LayoutProps,
  color,
  ColorProps,
} from "styled-system"

type Props = SpaceProps & LayoutProps & ColorProps

const Image = styled.img<Props>`
  ${space};
  ${layout};
  ${color};
`

export default Image
