import React, { useState } from "react"
import Cookies from "js-cookie"
import styled from "styled-components"
import { Box, Flex, Text } from "../../elements"

import RightArrow from "../../images/inline-svgs/arrow-right.svg"
import { Round as Spinner } from "../Spinner"

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()
    setLoading(true)
    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl =
      "https://api.hsforms.com/submissions/v3/integration/submit/5814022/195f7321-e7f5-4fe0-9c3e-49466380956f"
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "newsletter",
          value: "Yes",
        },
        {
          name: "lifecyclestage",
          value: "subscriber",
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: "I agree to receive communications from Traefik Labs",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive communications from Traefik Labs",
            },
          ],
        },
      },
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(res => res.json())
      .then(() => {
        setMessage("Thanks for submitting the form!")
        setEmail("")
      })
      .catch(err => {
        setMessage("Oops, something has gone wrong")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box width="100%">
      <form onSubmit={submitForm}>
        <Text
          as="label"
          fontSize={1}
          fontWeight="medium"
          mb={2}
          display="block"
          htmlFor="footerEmailInput"
        >
          Subscribe to our newsletter
        </Text>
        <Flex borderRadius={`6px`} overflow={`hidden`}>
          <Input
            type="email"
            name="email"
            placeholder="Your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            id="footerEmailInput"
          />
          <Submit type="submit" aria-label="Subscribe">
            {loading ? <Spinner color="#03192d" /> : <RightArrow />}
          </Submit>
        </Flex>
        <Flex mt={`16px`} className="legal-notice">
          <Checkbox
            type="checkbox"
            id="footerSubscribeNotice"
            name="subscribe"
            value="subscribe"
            required
          />
          <Text
            as="label"
            htmlFor="footerSubscribeNotice"
            fontSize={1}
            color="text.inverted.dark2x"
          >
            I agree to receive communications from Traefik Labs
          </Text>
        </Flex>
      </form>
      {message && (
        <Text as="div" fontSize={1} mt={`16px`}>
          {message}
        </Text>
      )}
    </Box>
  )
}

const Input = styled.input`
  background: ${props => props.theme.colors.background.base};
  border: none;
  padding: 0 12px;
  width: 100%;
  color: ${props => props.theme.colors.text.lighter};
  font-size: 1.4rem;
  height: 40px;
`

const Submit = styled.button`
  border: none;
  background: ${props => props.theme.colors.greys.light2x};
  color: ${props => props.theme.colors.text.dark};
  transition: all 0.2s;
  padding: 2px 10px;
  height: 40px;

  &:hover {
    background: ${props => props.theme.colors.greys.light};
  }
`

const Checkbox = styled.input`
  width: 17px;
  height: 17px;
  margin: 0 8px 0 0;
`

export default Subscribe
