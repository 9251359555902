import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Box, Heading, Grid } from "../../elements"

const Columns = styled(Grid)`
  list-style: none;
  padding: 0;
`

type ColumnProps = {
  title: string
}

const Column: React.FC<ColumnProps> = ({ title, children }) => (
  <Box as="li" m={0} p={0}>
    <Heading
      as="h2"
      variant="uppercase"
      fontSize={["1.1rem", null, null, "1.15rem"]}
      color="text.inverted.base"
      maxWidth="200px"
      lineHeight="1.6"
    >
      {title}
    </Heading>
    <List>{children}</List>
  </Box>
)

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 18px 0 0;
`

type ColumnItemProps = {
  variant: "internal" | "external"
  href: string
  rel?: string
}

const ColumnItem: React.FC<ColumnItemProps> = ({
  variant,
  href,
  children,
  ...props
}) => {
  return (
    <Li>
      {variant === "internal" ? (
        <Link to={href} {...props}>
          {children}
        </Link>
      ) : (
        <a href={href} {...props}>
          {children}
        </a>
      )}
    </Li>
  )
}

const Li = styled.li`
  padding: 0;
  margin: 10px 0 0;
  color: ${props => props.theme.colors.text.inverted.base};
  font-size: 1.4rem;

  &:first-child {
    margin-top: 0;
  }

  a {
    color: inherit;
    opacity: 0.7;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
`

export { Columns, Column, ColumnItem }
