import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled, { css as scss } from "styled-components"
import Cookies from "js-cookie"

import { Box, BoxedContainer, Flex, Button } from "../../elements"
import NavItem from "./NavItem"
import NavBeta from "./NavBeta"
import DrawerNav from "../DrawerNav"
import theme from "../../styles/theme"
import Search from "./Search"

import TraefikLabsLogo from "../../images/inline-svgs/traefik-labs.svg"

interface Props {
  hideNavigation: boolean
}

function Header({ hideNavigation }: Props) {
  const [isDrawerOpen, toggleDrawer] = useState(false)
  const [isHeaderFixed, setHeaderFixed] = useState(false)
  const [isAuthenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    if (Cookies.get("hub:authState")) {
      setAuthenticated(true)
    }
  }, [])

  useEffect(() => {
    let banner = document.querySelector(".announcement.visible")
    let makeHeaderSticky = function () {
      let breakpoint = 40
      if (!banner) {
        breakpoint = 0
      }
      if (
        document.body.scrollTop > breakpoint ||
        document.documentElement.scrollTop > breakpoint
      ) {
        setHeaderFixed(true)
      } else {
        setHeaderFixed(false)
      }
    }
    window.addEventListener("scroll", makeHeaderSticky)
    return () => {
      window.removeEventListener("scroll", makeHeaderSticky)
    }
  }, [])

  return (
    <Box position="relative" height="64px">
      <Container className="site-header" scrolled={isHeaderFixed}>
        <BoxedContainer
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          style={{
            position: "relative",
          }}
        >
          <Flex alignItems="center" height="100%">
            <Box>
              <Link to="/" aria-label="Traefik Labs">
                <TraefikLabsLogo />
              </Link>
            </Box>
            {!hideNavigation && <NavBeta />}
          </Flex>

          <Flex alignItems="center" height="100%">
            <Search />

            {/* 
            <Box
              display={["none", null, null, "block"]}
              m="0 30px 0 0"
              p={0}
              height="100%"
            >
              <NavItem
                name="Get in Touch"
                link={{
                  url: "/contact-us/",
                  type: "internal",
                }}
              />
            </Box> 
            */}

            <Flex>
              <Button
                elementType="link"
                href="https://info.traefik.io/en/request-demo-traefik-enterprise"
                lineHeight="1"
                padding={["7px", "7px 15px"]}
                color="text.dark"
                borderColor="text.dark"
                hollow
                mr={["7px", "15px"]}
                hover={{
                  bg: theme.colors.text.dark,
                  color: theme.colors.background.base,
                }}
                display={["none", "block"]}
              >
                Get a Demo
              </Button>

              {/* To be hidden while Hub is in beta */}
              {/* {isAuthenticated ? (
                <Button
                  elementType="link"
                  href="https://hub.traefik.io/dashboard"
                  lineHeight="1"
                  padding={["7px", "7px 15px"]}
                  color="text.dark"
                  borderColor="text.dark"
                  hollow
                  mr={["7px", "15px"]}
                  hover={{
                    bg: theme.colors.text.dark,
                    color: theme.colors.background.base,
                  }}
                >
                  Go to Dashboard
                </Button>
              ) : (
                <>
                  <Button
                    elementType="link"
                    href="https://hub.traefik.io/dashboard"
                    lineHeight="1"
                    padding={["7px", "7px 15px"]}
                    color="text.dark"
                    borderColor="text.dark"
                    hollow
                    mr={["7px", "15px"]}
                    display={["none", "block"]}
                    hover={{
                      bg: theme.colors.text.dark,
                      color: theme.colors.background.base,
                    }}
                  >
                    Log in
                  </Button>
                  <Button
                    elementType="link"
                    href="https://hub.traefik.io/dashboard"
                    lineHeight="1"
                    padding={["7px", "7px 15px"]}
                    bg="text.dark"
                    mr={["8px", 0]}
                    hover={{
                      bg: theme.colors.text.dark,
                    }}
                  >
                    Sign up
                  </Button>
                </>
              )} */}
            </Flex>

            {!hideNavigation && (
              <Hamburger className="drawer">
                <Box
                  className={"drawer-handle " + (isDrawerOpen ? "open" : "")}
                  onClick={() => toggleDrawer(!isDrawerOpen)}
                  m={["0 3px -13px 10px", "0 3px -13px 24px"]}
                >
                  <span className="first"></span>
                  <span className="second"></span>
                </Box>
              </Hamburger>
            )}
          </Flex>
        </BoxedContainer>
      </Container>

      {!hideNavigation && <DrawerNav isDrawerOpen={isDrawerOpen} />}
    </Box>
  )
}

const Container = styled.header<{ scrolled?: boolean }>`
  width: 100%;
  top: 0;
  left: 0;
  transition: height 0.1s;
  z-index: 100;
  background: white;
  position: relative;
  box-shadow: 0 0 7px 0 #00000021;
  height: 64px;
  display: flex;
  align-items: center;

  ${props =>
    props.scrolled &&
    scss`
      position: fixed;
      top: 0;
      height: 52px;
    `}
`

const Hamburger = styled.div`
  display: block;

  @media (min-width: 1150px) {
    display: none;
  }
`

export default Header
