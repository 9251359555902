import React from "react"
import styled from "styled-components"
import {
  color,
  typography,
  ColorProps,
  TypographyProps,
  space,
  SpaceProps,
} from "styled-system"

import checkCircularOutlineGreen from "../images/icons/check-circular-outline-green.svg"

type CustomBulletsListProps = ColorProps &
  TypographyProps &
  SpaceProps & {
    icon: string
    iconSize?: string
  }

/* List with a custom icon bullet */
export const CustomBulletList = styled.ul.attrs<CustomBulletsListProps>(
  props => ({
    icon: props.icon || ``,
    iconSize: props.iconSize || `24px`,
  })
)<CustomBulletsListProps>`
  list-style: none;
  font-size: 1.6rem;
  padding: 0;
  ${color}
  ${typography}
  ${space}

  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 calc(${props => props.iconSize} + 6px);

    &::before {
      content: "";
      background-image: url(${props => props.icon});
      width: ${props => props.iconSize};
      height: ${props => props.iconSize};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

export const CustomBulletListItem: React.FC = ({ children }) => (
  <li>
    <span>{children}</span>
  </li>
)

/* List with circular check marks */
export const CircularGreenCheckList = styled(CustomBulletList).attrs(props => ({
  icon: checkCircularOutlineGreen,
  iconSize: `17px`,
}))`
  li {
    line-height: 1.5;
    margin: 0 0 4px;

    &::before {
      top: 2px;
    }
  }
`
