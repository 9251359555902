import React from "react"
import styled from "styled-components"

interface Props {
  title: string
  description?: string
  accentColor?: string
}

export const HeaderCenterAligned = ({
  title,
  description,
  accentColor,
}: Props) => (
  <Wrapper>
    <Line className="hr-tiny" color={accentColor} />
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
  </Wrapper>
)

export default HeaderCenterAligned

const Wrapper = styled.div`
  margin: 0 0 80px;

  @media (max-width: ${p => p.theme.breakpointsObj.lg}) {
    margin: 0 0 60px;
  }
`

const Line = styled.hr`
  margin: 0 auto 32px;
  border-top: 4px solid ${p => p.color || p.theme.colors.primary.base};
`

const Title = styled.h2`
  font-size: 3.4rem;
  margin: 0 auto 21px;
  line-height: 1.33em;
  text-align: center;
  max-width: 800px;

  @media (max-width: ${p => p.theme.breakpointsObj.lg}) {
    font-size: 3rem;
  }

  @media (max-width: ${p => p.theme.breakpointsObj.sm}) {
    font-size: 2.5rem;
  }
`

const Description = styled.p`
  font-size: 2rem;
  opacity: 0.7;
  margin: 0 auto 31px;
  line-height: 1.6em;
  max-width: 800px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${p => p.theme.breakpointsObj.lg}) {
    font-size: 1.8rem;
    margin: 0 auto 22px;
  }
`
