import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Flex } from "../../elements"

const Copyright = () => (
  <Container flexDirection={["column", null, null, "row"]}>
    <div>Traefik Labs • Copyright © 2016-{new Date().getFullYear()}</div>
    <Flex ml={[0, null, null, "24px"]}>
      <Link to="/legal/privacy-and-cookie-policy/">Privacy Policy</Link>
      <Link to="/legal/end-user-license-agreement/">EULA</Link>
    </Flex>
  </Container>
)

const Container = styled(Flex)`
  color: ${props => props.theme.colors.text.inverted.dark2x};
  font-size: 1.4rem;

  a {
    color: ${props => props.theme.colors.text.inverted.dark2x};
    margin-left: 24px;

    &:hover {
      color: ${props => props.theme.colors.text.inverted.base};
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

export default Copyright
