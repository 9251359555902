import styled from "styled-components"
import {
  color,
  ColorProps,
  typography,
  TypographyProps,
  space,
  SpaceProps,
  variant,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
} from "styled-system"

type HeadingProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps & {
    variant: "default" | "lg" | "md" | "sm" | "xs" | "uppercase"
  }

const Heading = styled.h1<HeadingProps>(
  {
    margin: 0,
  },
  variant({
    variants: {
      default: {
        fontWeight: "bold",
        lineHeight: "1.33",
      },

      // Page title
      lg: {
        fontSize: ["3.4rem", null, null, "4rem", "4.8rem"],
        fontWeight: "bold",
        lineHeight: "1.17",
      },

      // Section title
      md: {
        fontSize: ["2.5rem", null, null, "3rem", "3.6rem"],
        fontWeight: "bold",
        lineHeight: "1.33",
      },

      sm: {
        fontSize: ["2rem", null, null, "2.4rem", "2.4rem"],
        fontWeight: "bold",
        lineHeight: "1.33",
      },

      xs: {
        fontSize: ["1.8rem"],
        fontWeight: "bold",
        lineHeight: "1.33",
      },

      // Uppercase title
      uppercase: {
        fontSize: ["1.5rem", null, null, "1.6rem"],
        color: "#7e89a7",
        fontWeight: "medium",
        lineHeight: "1.9",
        letterSpacing: "0.25em",
        textTransform: "uppercase",
        wordSpacing: "0.12em",
      },
    },
  }),
  color,
  typography,
  space,
  layout,
  flexbox
)

export default Heading
