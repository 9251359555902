import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  typography,
  position,
  border,
  ColorProps,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  PositionProps,
  BorderProps,
  shadow,
  ShadowProps,
  grid,
  GridProps,
  flexbox,
  FlexboxProps,
} from "styled-system"

const GridStyles = css`
  display: grid;
  grid-template-columns: repeat(${p => p.columns || 3}, 1fr);
  grid-gap: ${p => (p.increaseRowSpacing ? "50px" : "30px")} 30px;
  gap: ${p => (p.increaseRowSpacing ? "50px" : "30px")} 30px;

  @media (max-width: ${p => p.theme.breakpointsObj.xl}) {
    grid-gap: ${p => (p.increaseRowSpacing ? "50px" : "25px")} 25px;
    gap: ${p => (p.increaseRowSpacing ? "50px" : "25px")} 25px;
  }

  @media (max-width: ${p => p.theme.breakpointsObj.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${p => (p.increaseRowSpacing ? "40px" : "25px")} 25px;
    gap: ${p => (p.increaseRowSpacing ? "40px" : "25px")} 25px;
  }

  @media (max-width: ${p => p.theme.breakpointsObj.sm}) {
    grid-template-columns: 1fr;
  }
`

type Props = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  PositionProps &
  BorderProps &
  ShadowProps &
  GridProps &
  FlexboxProps

const Grid = styled.div<Props>`
  display: grid;
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${shadow}
  ${grid}
  ${flexbox}
`

export { GridStyles, Grid }
