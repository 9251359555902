import styled, { css } from "styled-components"
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
} from "styled-system"

import theme from "../styles/theme"

type Props = SpaceProps &
  ColorProps &
  TypographyProps &
  BorderProps &
  LayoutProps & {
    active?: boolean
    activeStyles?: {
      bg?: string
      color?: string
      borderColor?: string
    }
  }

const ToggleButton = styled.button.attrs((props: Props) => ({
  type: "button",
  color: props.color || theme.colors.text.base,
  bg: props.bg || theme.colors.background.base,
  borderColor: props.borderColor || theme.colors.borders.darker,
  activeStyles: {
    color:
      props.activeStyles?.color || props.bg || theme.colors.background.base,
    bg: props.activeStyles?.bg || theme.colors.primary.base,
    borderColor:
      props.activeStyles?.borderColor ||
      props.activeStyles?.bg ||
      theme.colors.primary.base,
  },
  active: props.active || false,
}))<Props>`
  ${color};

  border-radius: 17.5px;
  border: solid 1px;
  ${border};

  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 12px;
  line-height: 1;
  height: 35px;

  &:hover {
    background: ${p => p.activeStyles?.bg};
    color: ${p => p.activeStyles?.color};
    border-color: ${p => p.activeStyles?.borderColor};
  }

  ${props =>
    props.active &&
    css`
      background: ${props.activeStyles?.bg};
      color: ${props.activeStyles?.color};
      border-color: ${props.activeStyles?.borderColor};
    `}

  ${space};
  ${typography};
  ${layout};
`

export default ToggleButton
