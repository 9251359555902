import React from "react"
import styled from "styled-components"

import { Box, Flex, Heading, Paragraph } from "."

import CloseIcon from "../images/inline-svgs/close-icon.svg"

type modalProps = {
  children: React.ReactNode
  open: boolean
  setModal: (state: boolean) => void
}

const Modal: React.FC<modalProps> = ({ children, open, setModal }) => {
  if (!open) return null
  return (
    <Outer
      onClick={(e: React.MouseEvent): void => {
        // Only closes the modal if the user clicks on the blank area
        if (e.target === e.currentTarget) {
          setModal(false)
        }
      }}
    >
      <Inner>
        <CloseButton onClick={(): void => setModal(false)} title="close">
          <CloseIcon />
        </CloseButton>
        {children}
      </Inner>
    </Outer>
  )
}

const Outer = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: flex-start;
  justify-content: center;
  z-index: 100;
  padding: 50px 25px;
  overflow-y: scroll;

  @media (min-width: ${props => props.theme.breakpointsObj.xl}) {
    align-items: center;
  }
`

const Inner = styled(Flex)`
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.base};
  border-radius: ${props => props.theme.radius.default};
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px ${props => props.theme.colors.borders.base};
  padding: 50px 25px;
  position: relative;
  width: 100%;
  max-width: 620px;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 1.7;
  }
`

const ModalTitle: React.FC = ({ children, ...props }) => (
  <Heading variant="md" textAlign="center" {...props}>
    {children}
  </Heading>
)

const ModalParagraph: React.FC = ({ children, ...props }) => (
  <Paragraph
    variant="md"
    textAlign="center"
    mt={4}
    color="text.base"
    {...props}
  >
    {children}
  </Paragraph>
)

const ModalContent: React.FC = ({ children, ...props }) => (
  <Box mt={5} {...props}>
    {children}
  </Box>
)

export { Modal as default, ModalTitle, ModalParagraph, ModalContent }
