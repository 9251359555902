import styled from "styled-components"
import {
  color,
  typography,
  space,
  variant,
  ColorProps,
  TypographyProps,
  SpaceProps,
  layout,
  LayoutProps,
} from "styled-system"

type Props = ColorProps &
  TypographyProps &
  SpaceProps &
  LayoutProps & {
    variant?: "lg" | "md" | "sm" | "blockquote" | "default"
  }

const Paragraph = styled.p<Props>(
  variant({
    variants: {
      // Used below H1 headings in hero
      lg: {
        fontSize: ["1.8rem", null, null, "2.2rem", "2.4rem"],
        mb: 0,
        mt: "16px",
        lineHeight: "1.67",
      },

      // Used below H2 headings in sections
      md: {
        fontSize: ["1.8rem", null, null, null, "2rem"],
        mb: 0,
        lineHeight: "1.6",
      },

      // Used below H3 headings in sections
      sm: {
        fontSize: ["1.6rem"],
        mb: 0,
        lineHeight: "1.5",
      },

      // For testimonials
      blockquote: {
        fontSize: ["2.4rem", null, null, "3rem"],
        lineHeight: "1.33",
        border: "none",
        p: 0,
        m: 0,
      },

      default: {
        lineHeight: "1.33",
        fontSize: ["1.8rem"],
        mb: 0,
      },
    },
  }),
  space,
  color,
  typography,
  layout
)

Paragraph.defaultProps = {
  variant: "default",
}

export default Paragraph
