import styled from "styled-components"
import {
  color,
  space,
  layout,
  typography,
  position,
  border,
  ColorProps,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  PositionProps,
  BorderProps,
  shadow,
  ShadowProps,
  background,
  BackgroundProps,
  flexbox,
  FlexboxProps,
} from "styled-system"

type BoxProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  PositionProps &
  BorderProps &
  ShadowProps &
  BackgroundProps &
  FlexboxProps

const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${shadow}
  ${background}
  ${flexbox}
`

export default Box
