import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { Flex, Grid, Heading, Text, Box } from "../../elements"
import NavItem from "./NavItem"
import Product from "./Product"
import * as MenuColumn from "./MenuColumn"
import PostCard from "./PostCard"

import hubLogo from "../../images/header/products/traefik-hub.svg"
import enterpriseLogo from "../../images/header/products/traefik-enterprise.svg"
import proxyLogo from "../../images/header/products/traefik-proxy.svg"
import meshLogo from "../../images/header/products/traefik-mesh.svg"
import RocketIcon from "../../images/inline-svgs/navigation/rocket.svg"
import GithubIcon from "../../images/inline-svgs/navigation/github.svg"
import DocsIcon from "../../images/inline-svgs/navigation/documentation.svg"
import PriceTagIcon from "../../images/inline-svgs/navigation/price-tag.svg"
import LaptopIcon from "../../images/inline-svgs/navigation/laptop.svg"

const FETCH_LATEST_POSTS_QUERY = graphql`
  query {
    latestBlogPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "blog" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 1
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`

const NavBeta: React.FC = () => {
  const query = useStaticQuery(FETCH_LATEST_POSTS_QUERY)

  const blogPosts = query.latestBlogPosts?.edges

  return (
    <Wrapper
      as="nav"
      height="100%"
      alignItems="center"
      aria-label="Main menu"
      ml={`65px`}
    >
      <Flex
        as="ul"
        height="100%"
        alignItems="center"
        p={0}
        m={0}
        style={{ listStyle: "none" }}
      >
        <NavItem name="Products" hasSubmenu>
          <Grid gridTemplateColumns="repeat(2, 364px)">
            <Product
              title="Traefik Enterprise"
              description="All-in-one ingress, API management, and service mesh"
              logo={enterpriseLogo}
              url="/traefik-enterprise/"
              color="#337fe6"
              links={[
                {
                  title: "Pricing",
                  url: "/pricing/",
                  external: false,
                  icon: <PriceTagIcon aria-hidden="true" />,
                },
                {
                  title: "Free Trial",
                  url:
                    "https://info.traefik.io/get-traefik-enterprise-free-for-30-days",
                  external: true,
                  icon: <LaptopIcon aria-hidden="true" />,
                },
              ]}
            />
            <Product
              title="Traefik Hub"
              titleHtml={
                <Flex alignItems="center" mt={`12px`}>
                  <Heading variant="default" fontSize="2rem">
                    Traefik Hub
                  </Heading>
                  <Flex
                    bg="#7f8c2b"
                    borderRadius="6px"
                    textAlign="center"
                    p={["1px 5px 0 8px"]}
                    ml={`10px`}
                    height="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      as="div"
                      variant="uppercase"
                      color="white"
                      fontSize={["1.4rem", null, null, "1.4rem"]}
                      lineHeight="1"
                    >
                      Beta
                    </Text>
                  </Flex>
                </Flex>
              }
              description="Your Cloud Native Networking Platform"
              logo={hubLogo}
              url="/traefik-hub/"
              color="#7f8c2b"
              bgColor="#f4f5f6"
              links={[
                {
                  title: "Sign Up for Free",
                  url: "https://hub.traefik.io/",
                  external: true,
                  icon: <RocketIcon aria-hidden="true" />,
                },
              ]}
            />
          </Grid>
        </NavItem>

        <NavItem name="Open Source" hasSubmenu>
          <Grid gridTemplateColumns="repeat(2, 364px)">
            <Product
              title="Traefik Proxy"
              description="The Cloud-Native application proxy"
              logo={proxyLogo}
              url="/traefik/"
              color="#2aa2c1"
              links={[
                {
                  title: "GitHub",
                  url: "https://github.com/traefik/traefik",
                  external: true,
                  icon: <GithubIcon aria-hidden="true" />,
                },
                {
                  title: "Documentation",
                  url: "https://doc.traefik.io/traefik/",
                  external: true,
                  icon: <DocsIcon aria-hidden="true" />,
                },
              ]}
            />
            <Product
              title="Traefik Mesh"
              description="The simplest service mesh"
              logo={meshLogo}
              url="/traefik-mesh/"
              color="#be46dd"
              bgColor="#f4f5f6"
              links={[
                {
                  title: "GitHub",
                  url: "https://github.com/traefik/mesh",
                  external: true,
                  icon: <GithubIcon aria-hidden="true" />,
                },
                {
                  title: "Documentation",
                  url: "https://doc.traefik.io/traefik-mesh/",
                  external: true,
                  icon: <DocsIcon aria-hidden="true" />,
                },
              ]}
            />
          </Grid>
        </NavItem>

        <NavItem
          name="Learn"
          hasSubmenu
          menuProps={{
            right: "25px",
          }}
        >
          <Grid
            gridTemplateColumns="auto auto auto 288px"
            gridGap="93px"
            p="40px"
          >
            <MenuColumn.Column title="Learn">
              <MenuColumn.Item href="/blog/">Blog</MenuColumn.Item>
              <MenuColumn.Item href="/resources/">
                Resource Library
              </MenuColumn.Item>
              <MenuColumn.Item external href="https://academy.traefik.io/">
                Traefik Academy
              </MenuColumn.Item>
              <MenuColumn.Item href="/success-stories/">
                Success Stories
              </MenuColumn.Item>
              <MenuColumn.Item href="/events/">Events</MenuColumn.Item>
            </MenuColumn.Column>

            <MenuColumn.Column title="Documentation">
              <MenuColumn.Item external href="https://doc.traefik.io/traefik/">
                Traefik Proxy
              </MenuColumn.Item>
              <MenuColumn.Item
                external
                href="https://doc.traefik.io/traefik-mesh/"
              >
                Traefik Mesh
              </MenuColumn.Item>
              <MenuColumn.Item
                external
                href="https://doc.traefik.io/traefik-enterprise/"
              >
                Traefik Enterprise
              </MenuColumn.Item>
              <MenuColumn.Item href="/traefik-hub/">
                Traefik Hub{" "}
                <Flex
                  position="relative"
                  top="-2px"
                  display="inline-flex"
                  bg="#7f8c2b"
                  borderRadius="6px"
                  textAlign="center"
                  p={["1px 5px 0 8px"]}
                  ml={`4px`}
                  height="18px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    as="div"
                    variant="uppercase"
                    color="white"
                    fontSize={["1.1rem", null, null, "1.1rem"]}
                    lineHeight="1"
                  >
                    Beta
                  </Text>
                </Flex>
              </MenuColumn.Item>
            </MenuColumn.Column>

            <MenuColumn.Column title="Community">
              <MenuColumn.Item href="/community/">
                Community Overview
              </MenuColumn.Item>
              <MenuColumn.Item href="https://community.traefik.io/" external>
                Forum
              </MenuColumn.Item>
              <MenuColumn.Item href="/traefik-ambassador-program/">
                Traefik Ambassadors
              </MenuColumn.Item>
            </MenuColumn.Column>

            {blogPosts.map(({ node }) => (
              <PostCard post={node} key={node.id} />
            ))}
          </Grid>
        </NavItem>

        <NavItem
          name="Pricing"
          pr="40px"
          link={{
            type: "internal",
            url: "/pricing/",
          }}
        />
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  display: none;

  @media (min-width: 1150px) {
    display: flex;
  }
`

export default NavBeta
