import React from "react"
import styled from "styled-components"

import Discourse from "../../images/inline-svgs/social/discourse.svg"
import Github from "../../images/inline-svgs/social/github.svg"
import Twitter from "../../images/inline-svgs/social/twitter.svg"
import Linkedin from "../../images/inline-svgs/social/linkedin.svg"
import Youtube from "../../images/inline-svgs/social/youtube.svg"

const SocialMediaIcons = () => (
  <List>
    <Item>
      <Icon href="https://community.traefik.io/">
        <Discourse />
      </Icon>
    </Item>
    <Item>
      <Icon href="https://github.com/traefik" rel="nofollow">
        <Github />
      </Icon>
    </Item>
    <Item>
      <Icon href="https://twitter.com/traefik" rel="nofollow">
        <Twitter />
      </Icon>
    </Item>
    <Item>
      <Icon href="https://www.linkedin.com/company/traefik/" rel="nofollow">
        <Linkedin />
      </Icon>
    </Item>
    <Item>
      <Icon
        href="https://www.youtube.com/channel/UCVRyfsfkok4trq6u7RJsY_A"
        rel="nofollow"
      >
        <Youtube />
      </Icon>
    </Item>
  </List>
)

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  margin: 0 7px;
  padding: 0;
`

const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: ${props => props.theme.colors.text.inverted.base};

  &:hover {
    color: ${props => props.theme.colors.text.inverted.dark};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default SocialMediaIcons
