import React from "react"
import { Link, GatsbyLinkProps } from "gatsby"
import styled, { css } from "styled-components"
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
} from "styled-system"

import theme from "../styles/theme"
import Arrow from "../images/inline-svgs/arrow-right.svg"

const buttonStyles = css`
  font-size: 1.6rem;
  font-weight: ${props => props.theme.weights.medium};
  border-radius: 8px;
  border: 2px solid;
  transition: all 0.2s;
  line-height: 1.57;
  padding: 6px 14px;
  text-align: center;

  /* Button size */
  ${props =>
    props.large &&
    css`
      padding: 15px 17px;
    `}

  /* Lowercase/uppercase */
  ${props =>
    props.uppercase &&
    css`
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    `}

  &:hover {
    background-color: ${props => props.hover.bg};
    border-color: ${props => props.hover.borderColor};
    color: ${props => props.hover.color};
  }
  
  ${props =>
    props.arrow &&
    css`
      display: inline-flex;
    `}

  ${space};
  ${color};
  ${typography};
  ${border};
  ${layout};
`

const defaultButtonProps = props => ({
  bg: props.bg || (props.hollow ? `transparent` : theme.colors.primary.base),
  color:
    props.color ||
    (props.hollow
      ? theme.colors.primary.base
      : theme.colors.text.inverted.light),
  borderColor: props.borderColor || props.bg || theme.colors.primary.base,
  hover: {
    bg: props.hover?.bg || props.bg || theme.colors.primary.dark,
    color:
      props.hover?.color || props.color || theme.colors.text.inverted.light,
    borderColor:
      props.hover?.borderColor ||
      props.hover?.bg ||
      props.bg ||
      theme.colors.primary.dark,
  },
})

const StyledButton = styled.button.attrs(defaultButtonProps)`
  ${buttonStyles};
`

const StyledGatsbyLink = styled(Link).attrs(defaultButtonProps)`
  ${buttonStyles};
`

const StyledLink = styled.a.attrs(defaultButtonProps)`
  ${buttonStyles};
`

type ButtonProps = SpaceProps &
  ColorProps &
  TypographyProps &
  BorderProps &
  LayoutProps &
  GatsbyLinkProps & {
    elementType?: "gatsby_link" | "link" | "button"
    uppercase?: boolean
    hover?: {
      bg?: string
      color?: string
      borderColor?: string
    }
    hollow?: boolean
    large?: boolean
    arrow?: boolean
  }

const Button: React.FC<ButtonProps> = ({
  elementType,

  children,
  ...props
}) => {
  if (elementType === "gatsby_link") {
    return (
      <StyledGatsbyLink {...props}>
        {children}
        {props?.arrow && (
          <Icon>
            <Arrow />
          </Icon>
        )}
      </StyledGatsbyLink>
    )
  } else if (elementType === "link") {
    return <StyledLink {...props}>{children}</StyledLink>
  } else {
    return <StyledButton {...props}>{children}</StyledButton>
  }
}

const buttonRoundStyle = css<{
  $active?: boolean
}>`
  border-radius: 16px;
  border: solid 1px ${p => p.theme.colors.borders.darker};
  font-size: 1.3rem;
  font-weight: 500;
  color: ${p => p.theme.colors.text.base};
  padding: 0 12px;
  background: #fff;
  line-height: 32px;
  height: 32px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;

  &:hover {
    background: ${p => p.theme.colors.background.dark};
    color: #fff;
    border-color: ${p => p.theme.colors.background.dark};
  }

  &.active {
    background: ${p => p.theme.colors.background.dark};
    color: #fff;
    border-color: ${p => p.theme.colors.background.dark};
  }

  /* Lowercase/uppercase */
  ${props =>
    props.$active &&
    css`
      background: ${p => p.theme.colors.background.dark};
      color: #fff;
      border-color: ${p => p.theme.colors.background.dark};
    `}

  &:hover {
    background-color: ${props => props.theme.colors.background.dark};
    border-color: ${props => props.theme.colors.background.dark};
    color: #fff;
  }

  ${space};
  ${color};
  ${typography};
  ${border};
  ${layout};
`

export { Button, buttonRoundStyle }

const Icon = styled.span`
  width: 24px;
  height: 24px;
  margin-left: 6px;
  transition: transform 0.1s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
  }
`
