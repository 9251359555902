module.exports = {
  siteUrl: `https://traefik.io`,
  postsPerPage: 9,
  siteTitleMeta: `Traefik Labs: Makes Networking Boring`,
  siteDescriptionMeta: `Traefik is the world’s most popular cloud-native application networking stack, helping developers and devops build, deploy run microservices quickly and easily.`,
  shareImageWidth: 1000,
  shareImageHeight: 523,
  shortTitle: `Traefik`,
  siteIcon: `favicon.svg`,
}
