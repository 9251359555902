import React from "react"

const Spinner = () => {
  return (
    <div className="spinner active">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner
